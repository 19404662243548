.section-pages-linked-page-area {
    margin-top: 80px;
    margin-bottom: 80px;
    background-color: #F5F5F5;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.section-pages-linked-page-area .header {
    font-size: var(--font-size-h3);
    padding-top: 50px;
    padding-bottom: 20px;
}

.section-pages-linked-page-area .content {
    padding-bottom: 40px;
    max-width: 600px;
    text-align: center;
}

.section-pages-linked-page-area .k-button {
    background-color: #F5F5F5;
    color: var(--common-black);
    border: 1px solid #333333;
    margin-bottom: 50px;
    padding-left: 55px;
    padding-right: 55px;
}

.section-pages-linked-page-area .button-wrapper {
    max-width: 600px;
}

.section-pages-linked-page-area .k-button:focus,
.section-pages-linked-page-area .k-button:hover {
    background-color: rgb(229, 229, 229);
    color: var(--common-black) !important;
    text-decoration: none;
}

@media only screen and (max-width: 768px) {
    .section-pages-linked-page-area {
        margin-top: 40px;
        margin-bottom: 40px;
    }

    .section-pages-linked-page-area .header {
        margin-left: 15px;
        margin-right: 15px;
        padding-top: 40px;
        padding-bottom: 20px;
    }

    .section-pages-linked-page-area .content {
        margin-left: 15px;
        margin-right: 15px;
        padding-bottom: 30px;
        max-width: initial;
        text-align: left;
    }

    .section-pages-linked-page-area .k-button {
        margin-bottom: 40px;
        padding-left: 22.5px;
        padding-right: 22.5px;
    }

    .section-pages-linked-page-area .w-button {
        margin-left: 15px;
        margin-right: 15px;
    }

    .section-pages-linked-page-area .button-wrapper {
        width: 100%;
    }
} 

